<!-- 路由标签 -->
<template>
	<div id="routeTag">
		<el-tabs type="card" :closable="routeArr.length>1" v-model="curRoute" @tab-click="tabClick"
			@tab-remove="removeTab">
			<el-tab-pane v-for="(item,index) of routeArr" :key="item.name" :label="item.title" :name="item.path"
				:path="item.path">
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				curRoute: '',
				routeArr: [],
				user: ''
			}
		},
		watch: {
			$route(to, from) {
				this.getRouteTags();
			}
		},
		methods: {
			// 路由变化更新标签
			getRouteTags() {
				let itemKey = this.user.username + "_routes";
				let routeTags = localStorage.getItem(itemKey);
				if (routeTags == null || routeTags == undefined) {
					this.routeArr = [{
						name: this.$route.name,
						title: this.$route.meta.title,
						path: this.$route.fullPath
					}];
				} else {
					this.routeArr = JSON.parse(routeTags);
				}
				this.curRoute = this.$route.fullPath;
			},
			// 路由标签事件
			tabClick(tab) {
				this.$router.push({
					path: tab.name
				})
			},
			removeTab(tabName) {
				this.routeArr.forEach((item, index) => {
					if (item.path == tabName) {
						// 删除当前标签
						this.routeArr.splice(index, 1);
						// 更新存储路由
						let itemKey = this.user.username + "_routes";
						localStorage.setItem(itemKey, JSON.stringify(this.routeArr));
						if (tabName == this.curRoute) {
							this.$router.push({
								path: this.routeArr[0].path
							})
						}
					}
				})
			}
		},
		mounted() {
			let user = this.common.cGetUser();
			this.user = user;
			// 获取路由标签
			this.getRouteTags();
		}
	}
</script>

<style lang="scss">
	#routeTag {}
</style>
